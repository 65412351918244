import {
  container,
  mlAuto,
  mrAuto,
  title,
  cardTitle,
  description,
  coloredShadow,
  boxShadow,
  whiteColor,
  section,
  hexToRgb,
  textCenter,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const imgRounded = imagesStyles.imgRounded

const sponsorListAboutStyle = (theme) => ({
  container,
  mlAuto,
  mrAuto,
  title: {
    ...title,
    ...textCenter,
    marginTop: "0",
    marginBottom: "2rem",
  },
  titleBottom: {
    ...title,
    marginTop: "1rem",
  },
  section: {
    ...section,
    padding: "80px 0px",
  },
  description,
  coloredShadow,
  cardTitle,
  textCenter: {
    textAlign: "center",
  },
  projects: {
    padding: "80px 0",
  },
  tabSpace: {
    padding: "20px 0 50px 0px",
  },
  cardCategory: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
    marginTop: "10px",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important",
    marginTop: "10px !important",
  },
  cardDescription: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
  },
  sectionSpace: {
    height: "70px",
    display: "block",
  },
  marginTop20: {
    marginTop: "20px",
  },
  card2: {
    textAlign: "center",
  },
  card4: {
    marginTop: "0",
    marginBottom: "0",
    "& $cardTitle": {
      color: whiteColor,
    },
  },
  cardBody4: {
    paddingTop: "140px",
    paddingBottom: "140px",
  },
  info4: {
    padding: "0",
  },
  projects4: {
    "& hr": {
      margin: "70px auto",
      maxWidth: "970px",
    },
  },
  storyImage: {
    ...boxShadow,
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      margin: "3rem",
    },
  },
  sponsorListAboutImage: {
    maxHeight: "500px",
    ...imgRaised,
    ...imgRounded,
    "& img": {
      [theme.breakpoints.down("md")]: {
        objectFit: "contain",
      },
    },
  },
  foodTruckIcon: {
    width: "200px",
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginTop: "2rem",
    },
  },
})

export default sponsorListAboutStyle
