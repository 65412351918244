// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page icons
import fireIcon from "assets/img/icons/fire.svg"
import sponsorListAboutStyle from "assets/jss/material-kit-pro-react/views/sponsorListPageSections/sponsorListAboutStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(sponsorListAboutStyle)

export default function SponsorListAbout() {
  const { sponsorListAboutImage1 } = useStaticQuery(graphql`
    query sponsorListAboutImages {
      sponsorListAboutImage1: file(
        relativePath: { eq: "sponsors/scoville-pepper-scale.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Thank You to our Sponsors!</h2>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <GatsbyImage
              image={sponsorListAboutImage1.childImageSharp.gatsbyImageData}
              className={classes.sponsorListAboutImage}
              alt="Scoville Heat Levels"
            />
          </GridItem>
          <GridItem xs={12} sm={7} md={7} className={classes.mlAuto}>
            <div className={classes.kleanTunnelIcon}>
              <img
                src={fireIcon}
                className={classes.foodTruckIcon}
                alt="Fire Icon"
              />
            </div>
            <h4 className={classes.titleBottom}>Bringing the Heat!</h4>
            <p className={classes.description}>
              Chilimania would like to thank its new and returning sponsors.
              Without your support, we would not be able to produce such a
              special event, year in and year out, for more than a quarter of a
              century. Furthermore, without our sponsors we would not be able to
              raise money for the Edgerton community in the form of college
              scholarships and financial support to nonprofit organizations.
              Please patronize our sponsors and thank them for supporting
              Chilimania!
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
