// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import sponsorListPageStyle from "assets/jss/material-kit-pro-react/views/sponsorListPageStyle.jsx"
// core components
import CustomFooter from "components/Footer/CustomFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"
// sections for this page
import SponsorListAbout from "./Sections/SponsorListAbout.jsx"
import SponsorListSponsors from "./Sections/SponsorListSponsors.jsx"

const useStyles = makeStyles(sponsorListPageStyle)

export default function SponsorListPage() {
  const { sponsorListBackground } = useStaticQuery(graphql`
    query getSponsorListPageImages {
      sponsorListBackground: file(
        relativePath: { eq: "banners/sponsor-list.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div>
      <SEOHeader
        title="Sponsors - Chilimania"
        description="List of current Chilimania sponsors"
      />
      <CustomHeader />
      <ReactParallax
        imageData={sponsorListBackground.childImageSharp.gatsbyImageData}
        imageTitle={"Sponsor List Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem md={8} sm={8} className={classes.scholarshipsItem}>
              <h1 className={classes.pageTitle}>Chilimania Sponsors List</h1>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <SponsorListAbout />
        <SponsorListSponsors />
      </div>
      <SectionTimer />
      <CustomFooter />
    </div>
  )
}
